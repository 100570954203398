<template>
  <v-autocomplete
    v-model="formulary"
    :items="formularies"
    :loading="loading"
    :search-input.sync="search"
    label="Formulary"
    item-text="name"
    item-value="id"
    cache-items
    dense
    @change="$emit('update:value', formulary)"
  >
    <template #prepend>
      <v-icon size="22">
        fal fa-prescription-bottle-pill mt-1
      </v-icon>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
  data () {
    return {
      loading: false,
      search: null,
      formulary: this.value,
      formularies: [],
    }
  },
  watch: {
    search () {
      this.fetchFormularies()
    },
  },
  created () {
    this.fetchFormularies()
  },
  methods: {
    fetchFormularies () {
      this.loading = true
      const promise = this.axios.get('admin/drugs/formularies', {
        params:
          {
            count: 20,
            search: this.search,
          },
      })
      return promise.then((response) => {
        this.formularies = response.data.data
        this.loading = false
      }).catch(error => {
        this.$toast.error(error)
      })
    },
  },
}
</script>
